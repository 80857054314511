<template>
	<div class="form">
		<div>
			<StockItemForm formsType="Edit" />
		</div>
	</div>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api'
import StockItemForm from '@/_srcv2/pages/stock-ours/_shared/StockItemForm.vue'
import stockItemStore from '@/_srcv2/pages/stock-ours/store-stock/useStockItemState.js'
import Store from '@/store'
export default {
	name: 'EditStockItem',
	components: {
		StockItemForm,
	},
	setup() {
		const ourCompany = computed(() => Store.getters.getUserCurrentCompany)
		const { modelStock, schemaStock, selectedStockItemId } = stockItemStore()
		schemaStock.groups[0].fields[1].disabled = true
		onMounted(() => {
			modelStock.stock_id = selectedStockItemId.value
		})
		return { ourCompany }
	},
}
</script>

<style scoped>
.form {
	width: 450px;
	margin: auto;
	padding-top: 30px;
}
</style>
