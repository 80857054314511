/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)
import Store from '@/store'

// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'

// * ------------------------------------------------------------------------------------------

const stockItemState = reactive({
	selectedStockItemId: '',
})
const modelStock = reactive({
	stockId: '',
	stockName: '',
	stockUnit: '',
	stockPrice: 0,
	stockPriceA: 0,
	stockPriceB: 0,
	stockPriceC: 0,
	stockPriceD: 0,
	stockPriceS: 0,
	stockPriceZ: 0,
	stockPriceHRA: 0,
	stockPriceHRB: 0,
	stockPriceHRC: 0,
	stockPriceHRD: 0,
	stockMoms: '',
	stockGroup: '',
	stockAlarm: 0,
	stockShelf: '',
	companyId: Store.getters.getUserCurrentCompany,
	stockIsActive: true,
	campaignPrice: 0,
	isCampaignActive: false,
})
// *-------------------------------------------------------------
const schemaStock = reactive({
	groups: [
		{
			legend: 'Stock Item Details',
			id: 'details',
			featured: true,
			fields: [
				{
					type: 'input',
					inputType: 'text',
					label: 'Company',
					model: 'companyId',
					id: 'company_name',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'cleave',
					cleaveOptions: {
						blocks: [4, 4, 4],
						uppercase: true,
					},
					min: 9,
					max: 14,
					label: 'Stock ID',
					model: 'stockId',
					id: 'stock_id',
					readonly: false,
					disabled: false,
					featured: true,
					required: true,
					placeholder: 'Please enter stock item ID',
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Stock ID field! Errors:', errors)
					},
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Name',
					model: 'stockName',
					placeholder: 'Please enter stock item name',
					min: 8,
					max: 100,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Name field! Errors:', errors)
					},
				},
				{
					type: 'select',
					label: 'Group',
					model: 'stockGroup',
					hint: 'Please select stock item group',
					values: Store.getters.getStockGroupDropDown.sort(),
					featured: true,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Group field! Errors:', errors)
					},
				},
				{
					type: 'select',
					label: 'Unit',
					model: 'stockUnit',
					hint: 'Please select stock item unit',
					values: Store.getters.getStockUnitDropDown.sort(),
					featured: true,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Unit field! Errors:', errors)
					},
				},
				{
					type: 'select',
					label: 'Vat rate',
					model: 'stockMoms',
					hint: 'Please select stock item moms',
					values: Store.getters.getStockVatDropDown.sort(),
					featured: true,
					required: true,
					validator: ['integer', 'notnegativenumber'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Vat Rate field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: 'List Price',
					model: 'stockPrice',
					placeholder: 'Please enter stock item price',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: 'Price A Class',
					model: 'stockPriceA',
					placeholder: 'Please enter stock item price A',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price A field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: 'Price B Class',
					model: 'stockPriceB',
					placeholder: 'Please enter stock item price B',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price B field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: 'Price C Class',
					model: 'stockPriceC',
					placeholder: 'Please enter stock item price C',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price C field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: 'Price D Class',
					model: 'stockPriceD',
					placeholder: 'Please enter stock item price D',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price D field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: 'Price S Class',
					model: 'stockPriceS',
					placeholder: 'Please enter stock item price S',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price S field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: 'Price Z Class',
					model: 'stockPriceZ',
					placeholder: 'Please enter stock item price Z',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price Z field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: 'Price HRA Class',
					model: 'stockPriceHRA',
					placeholder: 'Please enter stock item price HRA',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Price HRA field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'cleave',
					label: 'Price HRB Class',
					model: 'stockPriceHRB',
					placeholder: 'Please enter stock item price HRB',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Price HRB field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'cleave',
					label: 'Price HRC Class',
					model: 'stockPriceHRC',
					placeholder: 'Please enter stock item price HRC',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Price HRC field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'cleave',
					label: 'Price HRD Class',
					model: 'stockPriceHRD',
					placeholder: 'Please enter stock item price HRD',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Price HRD field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'input',
					inputType: 'number',
					label: 'Stock Alarm',
					model: 'stockAlarm',
					placeholder: 'Please enter alarm amount',
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['integer', 'positivenumber'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Alarm field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					cleaveOptions: {
						blocks: [16],
						uppercase: true,
					},
					label: 'Shelf',
					model: 'stockShelf',
					placeholder: 'Please enter item shelf',
					min: 2,
					max: 15,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Stock Item Shelf field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'cleave',
					label: 'Stock Item Campaign Price',
					model: 'campaignPrice',
					placeholder: 'Please enter stock item campaign price',
					featured: true,
					required: false,
					validator: ['notnegativenumber', 'notnull'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price field! Errors:', errors)
					},
				},
				// * -----------------------------------------------------------------
				{
					type: 'checkbox',
					visible: false,
					label: 'Active',
					model: 'isCampaignActive',
					hint: 'Check the box if the item is active / uncheck the box if the item is inactive',
					featured: true,
					required: false,
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in is active field! Errors:',
								errors,
							)
						else {
							Store.dispatch('setCampaignIsActive', model.isCampaignActive)
						}
					},
				},
				{
					type: 'checkbox',
					visible: false,
					label: 'Active',
					model: 'stockIsActive',
					hint: 'Check the box if the item is active / uncheck the box if the item is inactive',
					featured: true,
					required: true,
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Name field! Errors:', errors)
					},
				},
			],
		},
	],
})
// *--------------------------------------------------------------------------
const formOptionsStock = reactive({
	validateAfterLoad: true,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'stockItem',
})
export default function stockItemStore() {
	return {
		...toRefs(stockItemState),
		modelStock,
		schemaStock,
		formOptionsStock,
		...toRefs(modelStock),
	}
}
