/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const formatValues = (value) => {
	return (value / 100).toFixed(2)
}
const state = reactive({
	isPopupVisible: false,
	columnsPizza: [
		{
			label: 'Select',
			field: 'select',
			width: '35px',
			sortable: false,
			thClass: 'center-it',
		},
		{
			label: 'Stock Id',
			field: 'stock_id',
			width: '130px',
			thClass: 'center-it',
		},
		{
			label: 'Item Name',
			field: 'stock_name',
			width: '340px',
			thClass: 'center-it',
		},
		{
			label: 'List Price',
			field: 'stock_price',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price A',
			field: 'stock_price_a',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price B',
			field: 'stock_price_b',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price C',
			field: 'stock_price_c',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price D',
			field: 'stock_price_d',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price S',
			field: 'stock_price_s',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price Z',
			field: 'stock_price_z',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
	],
	columnsHR: [
		{
			label: 'Select',
			field: 'select',
			width: '35px',
			sortable: false,
			thClass: 'center-it',
		},
		{
			label: 'Stock Id',
			field: 'stock_id',
			width: '130px',
			thClass: 'center-it',
		},
		{
			label: 'Item Name',
			field: 'stock_name',
			width: '340px',
			thClass: 'center-it',
		},
		{
			label: 'List Price',
			field: 'stock_price',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price HRA',
			field: 'stock_price_hra',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price HRB',
			field: 'stock_price_hrb',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price HRC',
			field: 'stock_price_hrc',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price HRD',
			field: 'stock_price_hrd',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price S',
			field: 'stock_price_s',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
		{
			label: 'Price Z',
			field: 'stock_price_z',
			width: '66px',
			thClass: 'center-it',
			formatFn: formatValues,
		},
	],
})

export default function useStockItemStore() {
	return {
		...toRefs(state),
	}
}
