<template>
	<div>
		<form id="form">
			<vue-form-generator
				@validated="onFormValidated"
				:schema="schemaStock"
				:model="modelStock"
				:options="formOptionsStock"
			>
			</vue-form-generator>
		</form>
		<b-field>
			<b-switch v-model="modelStock.isCampaignActive">
				{{
					modelStock.isCampaignActive
						? 'Campaign is active'
						: 'Campaign is NOT active'
				}}
			</b-switch>
		</b-field>
		<b-field class="margin-top-30">
			<b-tag type="is-success" size="is-large">
				You can make the stock item active or passive
			</b-tag>
		</b-field>
		<b-field>
			<b-switch v-model="modelStock.stockIsActive">
				{{
					modelStock.stockIsActive
						? 'Stock item is active'
						: 'Stock item is NOT active'
				}}
			</b-switch>
		</b-field>
		<div class="margin-top-30">
			<b-button
				:disabled="isDisabled"
				class="is-primary"
				type="submit"
				@click="onSubmitAdd"
				v-if="$props.formsType === 'Add'"
				>Save Item Details
			</b-button>
			<b-button
				class="is-success"
				v-if="$props.formsType === 'Add'"
				style="margin-left: 30px"
				@click="cleanTheForm"
				>Clean the form
			</b-button>
			<b-button
				v-if="$props.formsType === 'Add'"
				class="is-danger"
				style="margin-left: 30px"
				type="submit"
				@click="onCancel"
				>Cancel
			</b-button>
		</div>
		<div>
			<b-button
				:disabled="isDisabled"
				class="is-primary"
				type="submit"
				@click="onSubmitEdit"
				v-if="$props.formsType === 'Edit'"
				>Update Item Details
			</b-button>
			<b-button
				v-if="$props.formsType === 'Edit'"
				class="is-danger"
				style="margin-left: 50px"
				type="submit"
				@click="onCancel"
				>Cancel
			</b-button>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber.js'
import stockItemStore from '@/_srcv2/pages/stock-ours/store-stock/useStockItemState.js'
import AddStockItemMutation from '@/_srcv2/pages/stock-ours/graphql/AddStockItemMutation.graphql'
import UpdateStockItemMutation from '@/_srcv2/pages/stock-ours/graphql/UpdateStockItemMutation.graphql'
import { useQuery, useMutation } from '@vue/apollo-composable'
import GetStockGroupsQuery from '@/_srcv2/pages/stock-ours/graphql/GetStockGroupsQuery.graphql'
import GetVatRatesQuery from '@/_srcv2/pages/stock-ours/graphql/GetVatRatesQuery.graphql'
import GetStockUnitsQuery from '@/_srcv2/pages/stock-ours/graphql/GetStockUnitsQuery.graphql'
import Store from '@/store'
import useStockItemStore from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/useStockItemStore.js'

export default {
	name: 'StockItemForm',
	props: { formsType: String },
	setup() {
		// * -------------------------------------------------------------------------------
		// * Get Stock Groups
		const { refetch } = useQuery(
			GetStockGroupsQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
			}),
			() => ({
				fetchPolicy: 'no-cache',
			}),
		)
		refetch()
			.then((result) => {
				return result.data._type_stock_groups.map((item) => item.stock_groups)
			})
			.then((data) => {
				schemaStock.groups[0].fields[3].values = data
			})
		// ! --------------------------------------------------------------------
		// * Get Vat rates
		const { refetch: refetchVat } = useQuery(GetVatRatesQuery)
		refetchVat()
			.then((result) => {
				return result.data._type_moms_code.map((item) => item.moms_percent)
			})
			.then((data) => {
				schemaStock.groups[0].fields[5].values = data
			})
		// ! ---------------------------------------------------------------------
		// * Get units
		const { refetch: refetchUnit } = useQuery(GetStockUnitsQuery)
		refetchUnit()
			.then((result) => {
				return result.data._type_stock_unit.map((item) => item.stock_unit)
			})
			.then((data) => {
				schemaStock.groups[0].fields[4].values = data
			})
		// ! ---------------------------------------------------------------------
		const { cleaveToInt100 } = useReportNumber()
		const { modelStock, schemaStock, formOptionsStock } = stockItemStore()
		console.log('schemaStock', schemaStock)
		console.log(
			'schemaStock.groups[0].fields',
			schemaStock.groups[0].fields[4].values,
		)
		// * Add stock Item ****************************************************
		// ! ----------------------------------------------------------
		const {
			mutate: addNewItem,
			onDone: onDoneAddStock,
			onError: onErrorAddStock,
		} = useMutation(AddStockItemMutation, () => ({
			variables: {
				input: [
					{
						company_id: modelStock.companyId,
						stock_id: modelStock.stockId,
						stock_name: modelStock.stockName,
						stock_group: modelStock.stockGroup,
						stock_unit: modelStock.stockUnit,
						stock_moms: modelStock.stockMoms,
						stock_price: cleaveToInt100(modelStock.stockPrice),
						stock_price_a: cleaveToInt100(modelStock.stockPriceA),
						stock_price_b: cleaveToInt100(modelStock.stockPriceB),
						stock_price_c: cleaveToInt100(modelStock.stockPriceC),
						stock_price_d: cleaveToInt100(modelStock.stockPriceD),
						stock_price_s: cleaveToInt100(modelStock.stockPriceS),
						stock_price_z: cleaveToInt100(modelStock.stockPriceZ),
						stock_price_hra: cleaveToInt100(modelStock.stockPriceHRA),
						stock_price_hrb: cleaveToInt100(modelStock.stockPriceHRB),
						stock_price_hrc: cleaveToInt100(modelStock.stockPriceHRC),
						stock_price_hrd: cleaveToInt100(modelStock.stockPriceHRD),
						stock_alarm: modelStock.stockAlarm,
						stock_shelf: modelStock.stockShelf,
						stock_is_active: modelStock.stockIsActive,
						campaign_price: cleaveToInt100(modelStock.campaignPrice),
						is_campaign_active: modelStock.isCampaignActive,
					},
				],
			},
		}))
		// ! ------------------------------------------------------------
		onDoneAddStock((result) => {
			let resArr = result.data.insert_stock.returning[0]
			let popUpMsg = JSON.stringify(resArr)
			alert('The item has been saved', popUpMsg)
		})
		// ! ------------------------------------------------------------
		onErrorAddStock(() => {
			const errMessage =
				'Something went wrong. Item can not be saved check the data you have entered!!! <br>Stock ID must be unique!'
			alert('Error', errMessage)
		})
		// ! -----------------------------------------------------------------
		const onSubmitAdd = () => {
			addNewItem().then(() => cleanTheForm())
		}
		// ! --------------------------------------------------------------------
		const cleanTheForm = () => {
			modelStock.stockId = ''
			modelStock.stockName = ''
			modelStock.stockUnit = ''
			modelStock.stockPrice = 0
			modelStock.stockPriceA = 0
			modelStock.stockPriceB = 0
			modelStock.stockPriceC = 0
			modelStock.stockPriceD = 0
			modelStock.stockPriceS = 0
			modelStock.stockPriceZ = 0
			modelStock.stockPriceHRA = 0
			modelStock.stockPriceHRB = 0
			modelStock.stockPriceHRC = 0
			modelStock.stockPriceHRD = 0
			modelStock.stockMoms = ''
			modelStock.stockGroup = ''
			modelStock.stockAlarm = 0
			modelStock.stockShelf = ''
			modelStock.companyId = Store.getters.getUserCurrentCompany
			modelStock.stockIsActive = true
			modelStock.campaignPrice = 0
			modelStock.isCampaignActive = false
		}
		// * Edit Stock Item ****************************************************
		// * Update or view stock item query
		onMounted(() => {
			// Store.dispatch(
			// 	'setEditStockItemKey',
			// 	Store.getters.getEditStockItemKey + 1,
			// )
		})
		// ! ----------------------------------------------------------
		const {
			mutate: editStockItem,
			onDone: onDoneEditStockItem,
			onError: onErrorEditStockItem,
		} = useMutation(UpdateStockItemMutation, () => ({
			variables: {
				stock_id: modelStock.stockId,
				company_id: modelStock.companyId,
				input: {
					stock_name: modelStock.stockName,
					stock_group: modelStock.stockGroup,
					stock_unit: modelStock.stockUnit,
					stock_moms: modelStock.stockMoms,
					stock_price: cleaveToInt100(modelStock.stockPrice),
					stock_price_a: cleaveToInt100(modelStock.stockPriceA),
					stock_price_b: cleaveToInt100(modelStock.stockPriceB),
					stock_price_c: cleaveToInt100(modelStock.stockPriceC),
					stock_price_d: cleaveToInt100(modelStock.stockPriceD),
					stock_price_s: cleaveToInt100(modelStock.stockPriceS),
					stock_price_z: cleaveToInt100(modelStock.stockPriceZ),
					stock_price_hra: cleaveToInt100(modelStock.stockPriceHRA),
					stock_price_hrb: cleaveToInt100(modelStock.stockPriceHRB),
					stock_price_hrc: cleaveToInt100(modelStock.stockPriceHRC),
					stock_price_hrd: cleaveToInt100(modelStock.stockPriceHRD),
					stock_alarm: modelStock.stockAlarm,
					stock_shelf: modelStock.stockShelf,
					stock_is_active: modelStock.stockIsActive,
					campaign_price: cleaveToInt100(modelStock.campaignPrice),
					is_campaign_active: modelStock.isCampaignActive,
				},
			},
		}))
		// ! ------------------------------------------------------------
		onDoneEditStockItem((result) => {
			let resArr = result.data.update_stock.returning[0]
			let popUpMsg = JSON.stringify(resArr)
			alert('The item has been saved', popUpMsg)
		})
		// ! ------------------------------------------------------------
		onErrorEditStockItem(() => {
			const errMessage =
				'Something went wrong. Item can not be updated check the data you have entered!!! <br>Stock ID must be unique!'
			alert('Error', errMessage)
		})
		// ! -----------------------------------------------------------------
		const onSubmitEdit = () => {
			editStockItem().then(() => {
				cleanTheForm()
				isPopupVisible.value = false
			})
		}
		// ! --------------------------------------------------------------------
		const isDisabled = ref(true)
		const onFormValidated = (isValid, errors) => {
			if (isValid === true) {
				isDisabled.value = false
			} else if (errors.length !== 0) {
				isDisabled.value = true
			}
		}
		// * --------------------------------------------------------------------
		const { isPopupVisible } = useStockItemStore()
		const onCancel = () => {
			isPopupVisible.value = false
		}
		// ! --------------------------------------------------------------------
		return {
			modelStock,
			schemaStock,
			formOptionsStock,
			onSubmitAdd,
			onSubmitEdit,
			cleanTheForm,
			isDisabled,
			onFormValidated,
			// --------------------
			onCancel,
		}
	},
}
</script>
